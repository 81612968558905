body {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo {
  max-height: 512px;
  cursor: pointer;
}
